import React from "react";
import PropTypes from "prop-types";
import { useKeycloak } from "@react-keycloak/web";
import { Route, Redirect } from "react-router-dom";
import { login as loginRoute } from "../constants/routes";

import CircularProgress from "@material-ui/core/CircularProgress";

import HeaderBar from "../components/HeaderBar";
import FooterBar from "../components/FooterBar";

/**
 * A secure route component that checks for keycloak authentication; wraps react-router's Route.
 *
 * @param {object} props
 * @param props.component The component to (upon auth) render on this route.
 * @param props.path The path that triggers this route.
 * @returns {React.Element}
 */
const PrivateRoute = ({ component: Component, path }) => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <CircularProgress />;
  }
  return (
    <Route
      path={path}
      render={({ location }) =>
        keycloak.authenticated ? (
          <>
            <HeaderBar />
            <Component />
            <FooterBar />
          </>
        ) : (
          <Redirect to={{ pathName: loginRoute, state: { from: location } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string,
};

PrivateRoute.defaultProps = {
  path: "",
};

export default PrivateRoute;
