// NOTE: When adding colors, follow the following naming convention:
//
//  - Brand Colour
//    - Brand colours should follow the primary/secondary pattern, as a brand
//      will usually only consist of several main colours
//  - Color Family
//    - Color families consist of 4 colours that relate to a single "base" color.
//      These should always be named "light", "medium", "base", and "dark" to
//      signify their relation to the base color. This way, small future changes
//      require only a single update as well as no confusion when the name changes.
//  - Grey Colours
//    - The grey colour scheme has already been determined and adding another
//      colour is highly discouraged.
//  - Other
//    - Other colors should be clearly documented

const colors = {
  brand: {
    primary: "#00695C",
    secondary: "#EAF7F6",
  },
  chart: {
    green: {
      light: "#A6D4A8",
      medium: "#80C283",
    },
    red: {
      light: "#EDA4A4",
      medium: "#E67D7D",
    },
  },
  grey: {
    dark: "#212121",
    mediumDark: "#565656",
    mediumMedium: "#767676",
    medium: "#9E9E9E",
    light: "#D4D4D4",
    base: "#D0D0D0",
    mediumLight: "#F4F4F4",
    lightest: "#FAFAFA",
  },
  red: {
    light: "#FBE9E9",
    mediumLight: "#FF3333",
    medium: "#D50000",
    mediumDark: "#D70000",
    dark: "#9B0000",
    button: "#D50000",
  },
  green: {
    light: "#E8F5E9",
    mediumLight: "#69D641",
    medium: "#36A30E",
    base: "#36A30E",
    dark: "#037000",
    row: "#D0E6CA",
    button: "#1C8900",
  },
  teal: {
    light: "#80CBC4",
    mediumLight: "#4DB6AC",
    medium: "#26A69A",
    base: "#26A69A",
    dark: "#009688",
  },
  blue: {
    lightest: "#F5F8FF",
    light: "#E9F0FF",
    mediumLight: "#5C95FF",
    base: "#007AFF",
    baseDark: "#0786C1",
    medium: "#5381FF",
    mediumDark: "#002FCC",
    dark: "#2962FF",
    link: "#5381FF",
    button: "#2962FF",
  },
  turquoise: {
    light: "#EDF7F6",
    medium: "#39D1C3",
    dark: "#4FB6AC",
  },
  orange: {
    light: "#FFEEE5",
    medium: "#FF8433",
    mediumDark: "#E65100",
    dark: "#B31E00",
  },
  status: {
    new: "#FFADD9",
    overpaid: "#8B0000",
    filedRejected: "#D50000",
    approvedFailed: "#D50000",
    refund: "#905700",
    hold: "#EF8523",
    filedSucceededPrimary: "#1C8900",
    paid: "#333333",
    batched: "#333333",
    sentPrimary: "#333333",
    approvedPrimary: "#333333",
    inProgress: "#2346A7",
    patientLiability: "#79CBFF",
  },
  treatmentStatus: {
    attempted: "#EF8523",
    cancelled: "#FF0000",
    standby: "#2346A7",
    success: "#1C8900",
  },
  treatmentType: {
    laser: "#DCF5EF",
    swl: "#E7F4FF",
  },
  offwhite: "#FDFDFD",
  silver: "#C4C4C4",
  whitesmoke: "#F5F5F5",
  white: "#FFFFFF",
  black: "#000000",
};

export default colors;
