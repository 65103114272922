import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import HttpStatus from "http-status-codes";
import qs from "qs";
import keycloak from "../App/keycloak";

// Utilities
import config from "../config";
// import * as routes from "../constants/routes";

const { API_URL, GATEWAY_API_URL } = config;

// const noAccessPath = routes.basename + routes.noAccess;

const api = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  withCredentials: true,
  // Parameters are serialized to support arrays (bracket/repeated notation).
  // NOTE: This doesn't work with arrays of objects, which require "index" mode.
  //         However, since this is a slightly longer query is should be
  //         only used where appropriate (by overriding in request).
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: "brackets" }),
});

const gatewayApi = axios.create({
  baseURL: GATEWAY_API_URL,
  timeout: 1000,
  withCredentials: true,
});

const refreshAuthLogic = async (err) => {
  await gatewayApi
    .post("/refresh")
    .then((res) => Promise.resolve())
    .catch((error) => Promise.reject(error));
};

createAuthRefreshInterceptor(api, refreshAuthLogic);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response || {};
    if (status === HttpStatus.UNAUTHORIZED) {
      window.location.replace(`${GATEWAY_API_URL}/login/?redirectTo=/billing`);
      return { data: null };
    }

    // if (status === HttpStatus.FORBIDDEN && pathname !== noAccessPath) {
    //   // Users without permission will be sent to the no access page
    //   window.location.replace(noAccessPath);
    //   return { pathname };
    // }

    return Promise.reject(error);
  }
);

// Add Authentication header
api.interceptors.request.use((requestConfig) => {
  const updatedRequest = { ...requestConfig };
  updatedRequest.headers.Authorization = `Bearer ${keycloak.token}`;

  return updatedRequest;
});

export default api;
