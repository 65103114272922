import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";
import colors from "../constants/colors";

// styled components
const StyledFieldLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    flex-shrink: 0;
    color: ${colors.grey.mediumMedium};
    width: ${(props) => `${props.labelWidth}rem`};
    text-align: ${(props) => props.labelType !== "login" && "left"};
    font-size: ${(props) => props.labelType !== "login" && "0.8rem"};
    margin-right: ${(props) => props.labelType === "Extension" && "1rem"};
    margin-left: 0;
    margin-bottom: ${(props) => props.labelPlacement === "top" && `0.875rem`};
  }
  & .MuiFormControlLabel-root {
    justify-content: "flex-end";
    width: 100%;
  }
  & .MuiFormControl-marginNormal {
    margin-top: ${(props) => props.labelType !== "login" && 0};
    margin-bottom: ${(props) => props.labelType !== "login" && 0};
  }
`;

/**
 * Field Label Component
 *
 * @param {object} props
 * @param props.label
 * @param props.labelPlacement
 * @param props.control
 * @param props.labelType
 * @param props.style
 * @param props.labelWidth
 * @returns {React.Element}
 */
const FieldLabel = ({
  label,
  labelPlacement,
  control,
  labelType,
  labelWidth,
  style,
}) => (
  <StyledFieldLabel
    control={control}
    label={label}
    style={style}
    labelPlacement={labelPlacement}
    labelType={labelType}
    labelWidth={labelWidth}
  />
);

FieldLabel.propTypes = {
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  labelWidth: PropTypes.number,
  control: PropTypes.element.isRequired,
  labelType: PropTypes.string,
  style: PropTypes.shape(),
};

FieldLabel.defaultProps = {
  label: "",
  labelPlacement: "end",
  labelType: "5.5rem",
  labelWidth: 17,
  style: { marginLeft: 0 },
};

export default FieldLabel;
