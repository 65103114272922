import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControlLabel, Radio } from "@material-ui/core";
import FieldLabel from "./FieldLabel";

// styled components
const CustomRadio = styled(Radio)`
  svg {
    font-size: 1.2rem;
    stroke-width: 0.25px;
    color: #767676;
  }
  &:hover {
    background: transparent;
  }
`;

// the && is added to override the FieldLabel width
const CustomFormLabel = styled(FormControlLabel)`
  && .MuiFormControlLabel-label {
    font-family: "Lato";
    font-size: 0.9rem;
    color: #5c5c5c;
    width: fit-content;
  }
`;

/**
 * Radio Component
 *
 * @param {object} props
 * @param props.radioPlaceholder
 * @param props.fieldLabelPlaceholder
 * @param props.clickFunction
 * @param props.value
 * @returns {React.Element}
 */
const RadioButton = ({
  radioPlaceholder,
  fieldLabelPlaceholder,
  clickFunction,
  value,
}) => {
  const fieldLabelType = fieldLabelPlaceholder !== null ? "radio" : null;

  const handleRadioClick = () => {
    clickFunction(radioPlaceholder);
  };
  return (
    <FieldLabel
      control={
        <CustomFormLabel
          label={radioPlaceholder}
          labelPlacement="end"
          control={
            <CustomRadio
              checked={value === radioPlaceholder}
              onClick={handleRadioClick}
              disableRipple
            />
          }
        />
      }
      label={fieldLabelPlaceholder}
      labelPlacement="start"
      labelType={fieldLabelType}
    />
  );
};

RadioButton.propTypes = {
  radioPlaceholder: PropTypes.string.isRequired,
  fieldLabelPlaceholder: PropTypes.string,
  clickFunction: PropTypes.func.isRequired,
  value: PropTypes.string,
};

RadioButton.defaultProps = {
  fieldLabelPlaceholder: null,
  value: null,
};

export default RadioButton;
