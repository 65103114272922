import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Breadcrumbs, Link } from "@material-ui/core";

// styled components
const LinkText = styled(Link)`
  font-family: "Lato";
  text-align: center;
  font-size: 0.8rem;
  cursor: pointer;
  margin-left: ${(props) => props.textPosition === "second" && "0.3rem"};
  color: ${(props) => (props.textPosition === "first" ? "#2962ff" : "black")};
`;

const Polygon = styled.div`
  margin-left: 0.5rem;
  width: 0;
  height: 0;
  border-top: 0.2rem solid transparent;
  border-bottom: 0.2rem solid transparent;
  border-left: 0.4rem solid black;
`;

/**
 * Breadcrumb Component
 * TODO: The textPosition prop (and approach) isn't necessary, added to UI fixes ticket for later
 * https://git.focus21.io/tas/scheduling/-/issues/67
 *
 * @param {object} props
 * @param props.currentPage
 * @param props.currentPath
 * @returns {React.Element}
 */
const BreadCrumbs = ({ currentPage, currentPath }) => (
  <Breadcrumbs separator={<Polygon />} aria-label="breadcrumb">
    <LinkText textPosition="first" href="/">
      Dashboard
    </LinkText>
    <LinkText textPosition="second" href={currentPath}>
      {currentPage}
    </LinkText>
  </Breadcrumbs>
);

BreadCrumbs.propTypes = {
  currentPage: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
};

export default BreadCrumbs;
