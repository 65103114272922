import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import colors from "../constants/colors";

// styled components
const CustomButton = styled(Button)`
  background-color: white;
  color: ${colors.red.medium};
  border-color: ${colors.red.medium};
  height: 2.5rem;
  width: 7rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  margin-right: 1rem;
  font-weight: bold;
  text-align: center;
  font-size: 1em;

  &:hover {
    background-color: ${colors.red.medium};
    color: white;
    border-color: ${colors.red.medium};
  }
`;

/**
 * Red Button Component, wraps MUI's Button
 *
 * @param {object} props
 * @param {string} props.color
 * @param {Function} props.onClick
 * @param {string} props.type
 * @param {string} props.variant
 * @param {Array} props.children
 * @returns {React.Element}
 */
const RedButton = ({ color, onClick, type, variant, children }) => (
  <CustomButton color={color} onClick={onClick} type={type} variant={variant}>
    {children}
  </CustomButton>
);

RedButton.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string.isRequired,
};

RedButton.defaultProps = {
  onClick: () => {},
  type: "button",
};

export default RedButton;
