import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

// styled components
const StyledAdditionButton = styled(Button)`
  height: 2.25rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  font-weight: bold;
  text-align: center;
  font-size: 1em;
  color: #2346a7;

  &:hover {
    background-color: inherit;
  }
`;

const AddIcon = styled(AddCircleOutlineOutlinedIcon)`
  font-size: 1.25em;
  text-align: center;
`;

/**
 * Addition Button Component
 *
 * @param {object} props
 * @param props.children
 * @param props.onClick
 * @param props.variant
 * @returns {React.Element}
 */
const AdditionButton = ({ children, onClick, variant }) => (
  <StyledAdditionButton
    startIcon={<AddIcon />}
    onClick={onClick}
    variant={variant}
  >
    {children}
  </StyledAdditionButton>
);

AdditionButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string.isRequired,
};

AdditionButton.defaultProps = {
  onClick: () => {}, // this is temporary - will be changed when new physician popover is added
};

export default AdditionButton;
