import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Button } from "@material-ui/core";

// styled components
const TabButtonLeft = styled(Button)`
  display: relative;
  height: 100%;
  width: 5%;
  border: 1px solid #80c283;
  border-top-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0px;
  text-align: center;
  font-family: "Lato";
  font-size: 1em;
  background-color: ${(props) => (!props.checked ? "#80C283" : "#F5F5F5")};
  color: ${(props) => (!props.checked ? "white" : "#767676")};

  &:hover {
    background-color: ${(props) => (!props.checked ? "#659968" : "#e3e1e1")};
  }
`;

const TabButtonRight = styled(Button)`
  display: relative;
  height: 100%;
  width: 5%;
  border: 1px solid #80c283;
  border-top-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 8px;
  text-align: center;
  font-family: "Lato";
  font-size: 1em;
  background-color: ${(props) => (props.checked ? "#80C283" : "#F5F5F5")};
  color: ${(props) => (props.checked ? "white" : "#767676")};

  &:hover {
    background-color: ${(props) => (props.checked ? "#659968" : "#e3e1e1")};
  }
`;

/**
 * Alternating Tab Button Component
 *
 * @param {object} props
 * @param props.tags
 * @param props.toggleHook
 * @param props.checked
 * @param props.style
 * @returns {React.Element}
 */

const AlternatingTabButton = ({
  tags,
  toggleHook,
  checked,
  style,
  isMapped,
  mapObject,
  mapIndex,
}) => {
  const toggleLeft = () => {
    if (isMapped) {
      const mapState = [...mapObject];
      mapState[mapIndex][
        `Procedure ${mapIndex + 1}`
      ].alternatingButtonCheck = false;
      toggleHook(mapState);
    } else {
      toggleHook(false);
    }
  };
  const toggleRight = () => {
    if (isMapped) {
      const mapState = [...mapObject];
      mapState[mapIndex][
        `Procedure ${mapIndex + 1}`
      ].alternatingButtonCheck = true;
      toggleHook(mapState);
    } else {
      toggleHook(true);
    }
  };
  return (
    <Box style={style}>
      <TabButtonLeft checked={checked} onClick={toggleLeft}>
        {tags[0]}
      </TabButtonLeft>
      <TabButtonRight checked={checked} onClick={toggleRight}>
        {tags[1]}
      </TabButtonRight>
    </Box>
  );
};
AlternatingTabButton.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  checked: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
  toggleHook: PropTypes.func.isRequired,
  isMapped: PropTypes.bool,
  mapObject: PropTypes.arrayOf(PropTypes.object),
  mapIndex: PropTypes.number,
};

AlternatingTabButton.defaultProps = {
  isMapped: false,
  mapObject: null,
  mapIndex: null,
  style: {},
};

export default AlternatingTabButton;
