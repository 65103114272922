import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Radio, Grid } from "@material-ui/core";
import FieldLabel from "./FieldLabel";

// styled components
const CustomRadio = styled(Radio)`
  svg {
    font-size: 1.2rem;
    stroke-width: 0.25px;
    color: #767676;
  }
  &:hover {
    background: transparent;
  }
`;

const CustomFormLabel = styled(FieldLabel)`
  .MuiFormControlLabel-label {
    font-size: 0.9rem;
    color: #5c5c5c;
    width: 3.5rem;
  }
`;

const RadioRow = styled(Grid)`
  width: 100wh;
`;

/**
 * Radio Component
 *
 * @param {object} props
 * @param props.labelText
 * @param props.clickFunction
 * @param props.currentValue
 * @param props.menuItems
 * @returns {React.Element}
 */
const RadioGroup = ({ labelText, clickFunction, currentValue, menuItems }) => {
  const gridItemWidth = 12 / menuItems.length;
  return (
    <FieldLabel
      control={
        <RadioRow container>
          {menuItems.map((val) => (
            <Grid item xs={gridItemWidth}>
              <CustomFormLabel
                key={`radio-${labelText}-${val.text}`}
                label={val.text}
                labelPlacement="end"
                control={
                  <CustomRadio
                    checked={currentValue === val.value}
                    name={labelText}
                    onClick={() => clickFunction(val.value)}
                    value={val.value}
                    disableRipple
                  />
                }
              />
            </Grid>
          ))}
        </RadioRow>
      }
      label={labelText}
      labelPlacement="start"
    />
  );
};

RadioGroup.propTypes = {
  labelText: PropTypes.string,
  clickFunction: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

RadioGroup.defaultProps = {
  labelText: null,
};

export default RadioGroup;
