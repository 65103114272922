const API_PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
const API_BASE = process.env.REACT_APP_API_BASE;
const API_PATH = process.env.REACT_APP_API_PATH;
const API_HOST = `${API_BASE}${API_PATH}`;

const GATEWAY_HOST = process.env.REACT_APP_GATEWAY_HOST;

const IS_DEVELOPMENT = process.env.NODE_ENV === "development";
const IS_PRODUCTION = process.env.NODE_ENV === "production";
const IS_TESTING = process.env.NODE_ENV === "test";

// Make API URL multi-tenant
const tenantSubdomain = IS_DEVELOPMENT
  ? ""
  : `${window.location.host.split(".")[0]}.`; // Get tenant subdomain
const API_URL = `${API_PROTOCOL}${tenantSubdomain}${API_HOST}`;
const GATEWAY_API_URL = `${API_PROTOCOL}${tenantSubdomain}${GATEWAY_HOST}`;

const config = {
  IS_DEVELOPMENT,
  IS_PRODUCTION,
  IS_TESTING,
  API_URL,
  GATEWAY_API_URL,
  MICROSOFT_OAUTH_BASE_URL: process.env.REACT_APP_MICROSOFT_OAUTH_BASE_URL,
};

export default config;
