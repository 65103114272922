// Utilities
import colors from "./colors";
import { createMuiTheme } from "@material-ui/core/styles";

const MUI_SPACING = 8;

// Common spacing multiplier
const spacing = (multiplier = 1) => multiplier * MUI_SPACING;

const theme = {
  colors,
  misc: {
    statusLabel: {
      height: 24,
      width: 125,
    },
  },
  overrides: {
    TableEmptyComponent: {
      padding: spacing(6),
      textAlign: "center",
      color: colors.grey.medium,
    },
  },
  spacing,
};

const muiTheme = createMuiTheme({
  colors,
  palette: {
    primary: {
      main: "#2962ff",
    },
  },
  typography: {
    fontFamily: "Lato, 'Helvetica Neue', Arial, sans-serif",
    h1: {
      marginBottom: spacing(4),
      fontSize: 40,
      fontWeight: "normal",
    },
    h2: {
      marginBottom: spacing(3),
      fontSize: 32,
      fontWeight: "normal",
    },
    h3: {
      marginBottom: spacing(2),
      fontSize: 24,
      fontWeight: "normal",
    },
    h4: {
      marginBottom: spacing(1),
      fontSize: 18,
      fontWeight: "normal",
    },
    body1: {
      fontSize: 16,
    },
    button: {
      fontSize: 16,
      fontWeight: "bold",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
        textTransform: "none",
        fontSize: 16,
      },
      sizeLarge: {
        fontSize: 16,
      },
      sizeSmall: {
        fontSize: 14,
      },
      containedPrimary: {
        border: `2px solid ${colors.blue.button}`,
        color: "white",
        backgroundColor: `${colors.blue.button}`,
        "&:hover": {
          borderWidth: 2,
          borderColor: `${colors.blue.mediumDark}`,
          backgroundColor: `${colors.blue.mediumDark}`,
          color: "white",
        },
      },
      outlinedPrimary: {
        border: `2px solid ${colors.blue.button}`,
        color: `${colors.blue.button}`,
        "&:hover": {
          borderWidth: 2,
          borderColor: `${colors.blue.button}`,
          backgroundColor: `${colors.blue.button}`,
          color: "white",
        },
      },
      outlinedSecondary: {
        border: `2px solid ${colors.green.button}`,
        color: `${colors.green.button}`,
        "&:hover": {
          borderWidth: 2,
          borderColor: `${colors.green.button}`,
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 30,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        marginBottom: 0,
      },
    },
    // Non-alphabetized
    MuiFormControlLabel: {
      root: {
        padding: 0,
        marginLeft: 0,
        marginRight: 0,
        "&$disabled": {
          color: `${colors.black} !important`,
        },
      },
      label: {
        fontSize: 16,
      },
    },
    MuiRadioGroup: {
      root: {
        padding: 0,
        margin: 0,
      },
    },
    MuiInput: {
      root: {
        border: `1px solid ${colors.grey.light}`,
        borderRadius: 5,
        fontSize: 16,
        padding: 1,
        height: 40,
        "&$focused": {
          borderColor: colors.blue.dark,
          borderWidth: 2,
        },
        "&$disabled": {
          color: colors.black,
          backgroundColor: colors.grey.base,
        },
        "&$error": {
          borderColor: colors.red.medium,
          borderWidth: 2,
        },
      },
      input: {
        padding: "6px 8px 7px",
      },
      multiline: {
        padding: "6px 8px 7px",
      },
    },
    MuiTablePagination: {
      input: {
        height: "40px",
        width: "78px",
        borderRadius: "7px",
        borderColor: colors.grey.light,
      },
      select: {
        fontSize: "18px",
        paddingLeft: "0px",
        paddingRight: "35px",
        marginTop: "5px",
        marginLeft: "12px",
      },
      selectRoot: {
        marginLeft: 0,
        marginRight: 0,
        color: colors.black,
      },
      caption: {
        fontSize: "16px",
        marginRight: "10px",
        marginLeft: "10px",
        color: colors.black,
      },
      selectIcon: {
        height: "35px",
        width: "35px",
      },
      actions: {
        color: `${colors.black} !important`,
      },
    },
    MuiIconButton: {
      root: {
        color: colors.grey.dark,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 14,
        "&$error": {
          color: `${colors.red.medium} !important`,
        },
      },
    },
    MuiSelect: {
      select: {
        paddingRight: 32,
        "&:focus": {
          backgroundColor: "transparent",
        },
        "&$disabled": {
          color: colors.black,
        },
      },
      icon: {
        top: "calc(50% - 15px)",
        color: "inherit",
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover": {
          backgroundColor: colors.blue.light,
        },
        "&:focus": {
          backgroundColor: colors.blue.light,
        },
        "&$selected": {
          backgroundColor: `${colors.blue.light} !important`,
        },
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "collapse",
        minWidth: 950,
      },
    },
    MuiTableHead: {
      root: {
        whiteSpace: "nowrap",
      },
    },
    MuiTableRow: {
      root: {
        height: 0,
        border: "none",
      },
      head: {
        height: 0,
      },
      hover: {
        "&:hover": {
          backgroundColor: `${colors.grey.lightest} !important`,
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 16,
        color: colors.black,
        lineHeight: "19px",
        borderBottom: `1px solid ${colors.grey.medium}`,
        "&:first-child": {
          paddingLeft: spacing(3),
        },
      },
      head: {
        fontWeight: "bold",
        padding: `${spacing(1.5)}px ${spacing(1)}px`,
      },
      body: {
        padding: `${spacing(1)}px ${spacing(1)}px`,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: colors.black,
        fontWeight: "bold",
        fontSize: "16px",
      },
      icon: {
        marginLeft: 21,
        width: 18,
        height: 18,
      },
    },
    MuiToggleButton: {
      root: {
        color: `${colors.grey.medium} !important`,
        backgroundColor: colors.white,
        width: "42px",
        height: "42px",
        minWidth: "42px",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: "normal",
        alignItems: "center",
        justifyContent: "center",
        "&$selected": {
          backgroundColor: `${colors.blue.dark} !important`,
          color: `${colors.white} !important`,
          "&:after": {
            opacity: 0,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        borderWidth: "1px",
        borderColor: `${colors.grey.light} !important`,
        borderStyle: "solid",
        borderRadius: "8px",
      },
    },
    MuiDialog: {
      root: {
        paddingRight: "0 !important",
        height: "110%",
        marginTop: "-2.5%",
      },
      paper: {
        borderRadius: "8px",
        marginLeft: 10,
        marginRight: 10,
      },
      paperWidthLg: {
        width: "70%",
        maxWidth: 1010,
      },
      paperWidthXl: {
        width: "90%",
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.black,
        "&$focused": {
          color: colors.black,
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.blue.dark,
      },
    },
    MuiFormGroup: {
      root: {},
    },
    MuiTab: {
      root: {
        minWidth: 0,
        "@media (min-width: 960px) and (max-width: 1185px)": {
          minWidth: "110px",
        },
        "@media (min-width: 1186px)": {
          minWidth: "140px",
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        border: `1px solid ${colors.grey.light}`,
        boxShadow: "none",
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        borderBottom: `1px solid ${colors.grey.light}`,
        margin: 0,
        maxHeight: 50,
        "& .MuiTypography-body1": {
          fontSize: 15,
          fontWeight: "bold",
          margin: 6,
        },
        "&$expanded": {
          alignItems: "center",
          minHeight: "50px",
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        flexDirection: "column",
        padding: `${spacing(2)}px ${spacing(3)}px`,
        "& > div:not(:last-of-type)": {
          marginBottom: spacing(2),
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: "7px !important",
      },
    },
  },
});

// Table style
const commonStyles = {
  tabContent: {
    width: "100%",
    padding: spacing(2),
    border: `1px solid ${colors.grey.medium}`,
    boxSizing: "border-box",
    overflowX: "auto",
  },
  tabs: {
    marginTop: spacing(3),
  },
  tablePagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: spacing(2),
    "& > *:not(:first-child)": {
      marginLeft: spacing(),
    },
  },
  tablePageCountSelect: {
    height: 30,
    minWidth: 60,
    marginRight: spacing(),
    fontSize: 14,
    textAlign: "center",
    "& .MuiSelect-root": {
      paddingRight: 24,
    },
    "& .MuiSelect-icon": {
      width: 24,
    },
  },
  tablePageCountSelectMenu: {
    "& > li": {
      minHeight: "auto",
      fontSize: 14,
    },
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.grey.lightest,
    },
    "& td": {
      padding: `${spacing(0.5)}px ${spacing()}px`,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    "& td:first-child": {
      paddingLeft: `${spacing(3)}px !important`,
    },
  },
  cellsWithNoIcons: {
    "& td": {
      padding: "11px 8px !important",
    },
  },
};

export default theme;
export { muiTheme, commonStyles };
