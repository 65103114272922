import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import colors from "../constants/colors";

// styled components
const CustomButton = styled(Button)`
  background-color: ${colors.offwhite};
  height: 2.5rem;
  width: ${(props) => props.width}rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  margin-right: 1rem;
  font-weight: bold;
  text-align: center;
  font-size: 1em;

  &:hover {
    background-color: ${colors.green.button};
    color: white;
  }
`;

/**
 * Green Button Component, wraps MUI's Button
 *
 * @param {object} props
 * @param {string} props.color
 * @param {Function} props.onClick
 * @param {string} props.type
 * @param {string} props.variant
 * @param {Array} props.children
 * @param {Function} props.startIcon
 * @param {number} props.width
 * @param {boolean} props.isDisabled
 * @returns {React.Element}
 */
const GreenButton = ({
  color,
  onClick,
  startIcon,
  type,
  variant,
  width,
  children,
  isDisabled,
}) => (
  <CustomButton
    color={color}
    onClick={onClick}
    startIcon={startIcon}
    type={type}
    variant={variant}
    width={width}
    disabled={isDisabled}
  >
    {children}
  </CustomButton>
);

GreenButton.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string.isRequired,
  width: PropTypes.number,
};

GreenButton.defaultProps = {
  isDisabled: false,
  onClick: () => {},
  startIcon: () => {},
  type: "button",
  width: 12,
};

export default GreenButton;
