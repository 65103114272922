import React from "react";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import PrivateRoute from "./PrivateRoute";

// Components
import LoginPage from "../pages/Login";
import PatientSearch from "../pages/PatientSearch";
import NewAppointmentPage from "../pages/NewAppointment";
import NewPatientPage from "../pages/NewPatient";
import CircularProgress from "@material-ui/core/CircularProgress";
import HeaderBar from "../components/HeaderBar";
import FooterBar from "../components/FooterBar";

// Utilities
import * as routes from "../constants/routes";

/**
 * Main application router
 *
 * @returns {React.Element} The app router.
 */
const AppRouter = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <CircularProgress />;
  }

  return (
    <Router>
      <Switch>
        <Route
          path={routes.login}
          component={() => (
            <>
              <HeaderBar />
              <LoginPage />
              <FooterBar />
            </>
          )}
        />
        <PrivateRoute
          exact
          path={routes.patientSearch}
          component={PatientSearch}
        />
        <PrivateRoute
          exact
          path={routes.appointmentNew}
          component={NewAppointmentPage}
        />
        <PrivateRoute path={routes.patientsNew} component={NewPatientPage} />
        <Redirect exact from="/" to={routes.login} />
        <Route path={routes.login} component={LoginPage} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
