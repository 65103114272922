import Keycloak from "keycloak-js";

// Set up Keycloak instance
const keycloak = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL,
  "ssl-required": process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED,
  clientId: process.env.REACT_APP_KEYCLOAK_RESOURCE,
  "public-client": process.env.REACT_APP_KEYCLOAK_PUBLIC_CLIENT,
  "verify-token-audience": process.env.REACT_APP_KEYCLOAK_VERIFY_TOKEN_AUDIENCE,
  "use-resource-role-mappings":
    process.env.REACT_APP_KEYCLOAK_USE_RESOURCE_ROLE_MAPPINGS,
  "confidential-port": process.env.REACT_APP_KEYCLOAK_CONFIDENTIAL_PORT,
});

export default keycloak;
