import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Typography, Box, Grid } from "@material-ui/core";
import DropDown from "../../components/DropDown";
import DatePicker from "../../components/DatePicker";
import AdditionButton from "../../components/AdditionButton";
import TextInput from "../../components/TextInput";
import BreadCrumbs from "../../components/BreadCrumbs";
import BlueButton from "../../components/BlueButton";
import RedButton from "../../components/RedButton";
import RadioGroup from "../../components/RadioGroup";
import { postPatient } from "../../apiCalls";

// styled components
const Wrapper = styled(Grid)`
  height: fit-content;
  background-color: white;
  padding-left: 2rem;
`;

const HeaderLarge = styled(Typography)`
  font-family: "Lato";
  text-align: left;
  color: #333333;
  font-size: 2rem;
`;

const HeaderSmall = styled(Typography)`
  font-family: "Lato";
  text-align: left;
  color: #5c5c5c;
  font-size: 1.5rem;
`;

const Divider = styled(Box)`
  position: relative;
  width: 95%;
  height: 0.1rem;
  background-color: rgba(128, 194, 131, 0.88);
  margin-top: 1rem;
`;

const NewAppointmentPage = () => {
  const INSURANCE_PROVIDERS = ["Provider 1", "Provider 2", "Provider 3"];
  const SEXES = [
    { text: "Male", value: "1" },
    { text: "Female", value: "2" },
  ];
  const [insuranceProvider, setInsuranceProvider] = useState(
    INSURANCE_PROVIDERS[0]
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [insuranceGroup, setInsuranceGroup] = useState("");
  const [memberId, setMemberId] = useState("");
  const [sex, setSex] = useState("");
  const [dob, setDob] = useState(null);
  const [saveError, setSaveError] = useState(null);

  const submitForm = (redirectPage) => {
    const patient = {
      tenant: 1,
      firstName,
      lastName,
      dob,
      sex,
      insuranceProvider,
      cellphone,
      email,
      insuranceGroup,
      memberId,
    };
    setSaveError(false);
    postPatient(patient)
      .then((response) => goToPage(redirectPage))
      .catch(() => setSaveError(true)); // A message indicating that something went wrong should be rendered
  };

  const history = useHistory();

  const goToPage = (path, payload = null) => {
    history.push(path, payload);
  };

  const handleChange = (event, type) => {
    switch (type) {
      case "insuranceProvider":
        setInsuranceProvider(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleDateChange = (date, type) => {
    switch (type) {
      case "dob":
        setDob(date);
        break;
      default:
        break;
    }
  };

  const validateInputs = () => {
    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      sex > 0 &&
      insuranceProvider.length > 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <Wrapper container>
      <Grid item xs={12} style={{ marginTop: "1rem" }}>
        <BreadCrumbs currentPage="New Patient" currentPath="/patients/new" />
      </Grid>
      <Grid item xs={12} style={{ marginTop: "1.5rem" }}>
        <HeaderLarge>New Patient</HeaderLarge>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "1.5rem" }}>
        <HeaderSmall>Patient Information</HeaderSmall>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
        <Grid container item>
          <Grid item xs={4}>
            <TextInput
              fieldLabelPlaceholder="Last Name*"
              textFieldPlaceholder=""
              setValue={setLastName}
              value={lastName}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              fieldLabelPlaceholder="First Name*"
              textFieldPlaceholder=""
              setValue={setFirstName}
              value={firstName}
            />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={4}>
            <DatePicker
              dateState={dob}
              type="dob"
              label="Date of Birth*"
              dateChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={4}>
            <RadioGroup
              labelText="Sex*"
              clickFunction={setSex}
              currentValue={sex}
              menuItems={SEXES}
            />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={4}>
            <TextInput
              fieldLabelPlaceholder="Cell Phone"
              textFieldPlaceholder=""
              setValue={setCellphone}
              value={cellphone}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              // This has to have a space at the end for now because just "Email" sets a different hardcoded width.
              // The label needs to be more versatile for this to change.
              fieldLabelPlaceholder="Email "
              textFieldPlaceholder=""
              setValue={setEmail}
              value={email}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container style={{ marginTop: "1.25rem" }}>
          <HeaderSmall>Insurance Information</HeaderSmall>
        </Grid>
        <Grid container item style={{ marginTop: "1rem" }}>
          <Grid item>
            <DropDown
              menuItems={INSURANCE_PROVIDERS}
              dropdownType="insuranceProvider"
              currentValue={insuranceProvider}
              label="Insurance Provider"
              style={{
                color: "#BC1ED6",
                fontWeight: "bold",
              }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <AdditionButton border={false} style={{ marginLeft: "2rem" }}>
              Add Another Insurance
            </AdditionButton>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={4}>
            <TextInput
              fieldLabelPlaceholder="Group #"
              textFieldPlaceholder=""
              setValue={setInsuranceGroup}
              value={insuranceGroup}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              fieldLabelPlaceholder="Member ID"
              textFieldPlaceholder=""
              setValue={setMemberId}
              value={memberId}
            />
          </Grid>
        </Grid>
        <Divider />
        {/* This is a placeholder error message, needs to be something prettier and with some functionality */}
        {saveError ? <Grid>There was an error saving</Grid> : ""}
        <Grid container item style={{ marginTop: "0.75rem" }}>
          <Grid item xs={8}>
            <RedButton
              color="secondary"
              type="button"
              variant="outlined"
              data-testid="cancel-button"
              onClick={() => goToPage("/patient-search")}
            >
              Cancel
            </RedButton>
          </Grid>
          <Grid item xs={2}>
            <BlueButton
              color="primary"
              variant="outlined"
              data-testid="save-button"
              style={{ marginLeft: "6.5rem" }}
              onClick={() => submitForm("/patient-search")}
              isDisabled={validateInputs()}
            >
              Save
            </BlueButton>
          </Grid>
          <Grid item xs={2}>
            <BlueButton
              color="primary"
              variant="contained"
              data-testid="save-and-create-appointment-button"
              onClick={() => submitForm("/appointments/new")}
              style={{
                borderColor: validateInputs() && "rgba(0, 0, 0, 0.12)",
                marginLeft: "1rem",
              }}
              isDisabled={validateInputs()}
            >
              Save & Create Appointment
            </BlueButton>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default NewAppointmentPage;
