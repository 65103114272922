import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  MuiPickersUtilsProvider,
  TimePicker as TimePickerLib,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import colors from "../constants/colors";
import FieldLabel from "./FieldLabel";

// styled components
const CustomTimePicker = styled(TimePickerLib)`
  background-color: ${colors.white};
  width: 8rem;
  border-radius: 8;
  border-color: ${colors.grey.light};

  .MuiInput-root.Mui-focused {
    border-width: 1px;
  }
`;

// function that format the selected way so its nicer to read within the picker
const formatTime = (dateValue) => {
  let displayVal = "";
  if (dateValue != null) {
    const dateVal = new Date(dateValue);
    const timeVal = dateVal.toLocaleTimeString("en-US");
    const timeSplit = timeVal.split(":");
    displayVal = `${timeSplit[0]}:${timeSplit[1]}`;
  }

  return displayVal;
};

/**
 * Time Picker Component
 *
 * @param {object} props
 * @param props.timeState
 * @param props.label
 * @param props.onChange
 * @param props.onAccept
 * @param props.initialFocusedDate
 * @returns {React.Element}
 */

const TimePicker = ({
  timeState,
  label,
  onChange,
  onAccept,
  initialFocusedDate,
}) => (
  <FieldLabel
    control={
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CustomTimePicker
          InputProps={{
            disableUnderline: true,
          }}
          variant="inline"
          value={timeState}
          format={formatTime(timeState)}
          initialFocusedDate={initialFocusedDate()}
          onChange={onChange}
          onAccept={onAccept}
        />
      </MuiPickersUtilsProvider>
    }
    label={label}
    labelPlacement="start"
    labelType="timePicker"
  />
);

TimePicker.propTypes = {
  timeState: PropTypes.instanceOf(Date),
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  initialFocusedDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.func,
  ]).isRequired,
};

TimePicker.defaultProps = {
  timeState: null,
};

export default TimePicker;
