import React, { useCallback } from "react";
import styled from "styled-components";
import { useKeycloak } from "@react-keycloak/web";
import { Box, Button, Typography } from "@material-ui/core";
import nextmedLogo from "../../assets/nextmed-logo.png";
import { useLocation, Redirect } from "react-router-dom";
import { patientSearch } from "../../constants/routes";

const Background = styled(Box)`
  background: linear-gradient(
    246.17deg,
    rgba(128, 194, 131, 0.88) 23.88%,
    #2962ff 99.39%,
    #2346a7 111.05%
  );
  height: 92vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginContainer = styled(Box)`
  height: fit-content;
  width: 43.125rem;
  background-color: #fdfdfd;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 4rem;
`;

const LogoContainer = styled(Box)`
  position: relative;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
`;

const StyledLogo = styled.img`
  width: 12rem;
`;

const HeaderText = styled(Typography)`
  font-size: 2.5em;
  color: #333333;
  text-align: center;
`;

const LoginButton = styled(Button)`
  background-color: #80c283;
  width: 10rem;
  height: 2.5rem;
  font-weight: bold;
  color: white;
  border-radius: 0.5rem;

  &:hover {
    background-color: #94d497;
  }
`;

const VerticalSpacer = styled(Box)`
  width: 5px;
  height: 10rem;
`;

/**
 * Scheduling Login Page
 *
 * @returns {React.Element}
 *
 */

const LoginPage = () => {
  const location = useLocation();

  // If user was redirected from another page, store that page path
  const { from } = location.state || {
    from: { pathname: patientSearch },
  };

  // Initialize Keycloak
  const { keycloak } = useKeycloak();

  // Authenticate and then redirect to test page
  const login = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  // If already logged in, redirect to source page
  if (keycloak?.authenticated) {
    return <Redirect to={from} />;
  }

  return (
    <Background>
      <LoginContainer>
        <LogoContainer>
          <StyledLogo src={nextmedLogo} />
        </LogoContainer>
        <HeaderText style={{ marginTop: "0.5em" }}>Welcome</HeaderText>
        <VerticalSpacer />
        <LoginButton onClick={login}>Secure Sign-On</LoginButton>
      </LoginContainer>
    </Background>
  );
};

export default LoginPage;
