import api from "./api";

/**
 * Get a patient with the specified id
 *
 * @param patientId
 * @returns Patient
 */
const getPatient = (patientId) => api.get(`/patients/${patientId}`);

/**
 * Send Email
 *
 * @param {object} payload - Request payload
 * @returns Created Patient
 */
const postPatient = (payload) => api.post("/patients/", payload);

export { getPatient, postPatient };
